import React from 'react'

import Layout from '../components/layout'

const EpaperPage = () => {
  function redirect(e) {
    e.preventDefault()
    const token = window.store.getState().profile.shortLivedToken
    window.location.href = `https://lantmannen.prenly.com/authority/auth-token?auth_token=${token}`
  }

  return (
    <Layout articlelevel={102} actionBtn={false}>
      <>
        <div className="contentWrapper flex">
          <div className="content flex emagazine">
            <img
              width={308}
              height={400}
              alt="Etidningen"
              src="https://mediacdn.prenly.com/api/image/lantmannen.prenly.com/3447/latest-issue"
            />
            <a className="magazineRedirectBtn" onClick={redirect} href="">
              Tryck här för att öppna e-tidningen
            </a>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default EpaperPage
